import Calendar from './Calendar.js';

function Step5(props) {
  return (
    <div className='container-fluid'>
      <div className='footer-container footer-container-large row'>
        <div className='col-md-12'>
          <h3>
            <i className='em em-bulb' />
            {' '}
            Recuerda que tu paquete llega una vez al mes, pero la
            {' '}
            <i className='em em-bulb' />
            <br />
            comida puede llegar más veces si así lo prefieres.
          </h3>
        </div>
      </div>
      
      <Calendar
        butLeftVisible={true}
        butLeftText='Fecha de tu paquete'
        butLeftWidth={props.isInOrderItems('food') ? 6 : 12}
        butRightVisible={props.isInOrderItems('food')}
        butRightText='Fechas extra de comida (opcional)'
        butRightWidth={6}
        isInOrderMultipleDates={props.isInOrderMultipleDates}
        toggleOrderMultipleDate={props.toggleOrderMultipleDate}
        isOrderUniqueDate={props.isOrderUniqueDate}
        setOrderUniqueDate={props.setOrderUniqueDate}
        isInOrderItems={props.isInOrderItems} />
    </div>
  );
}

export default Step5;
