import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from './pages/Home.js';
import Register from './pages/Register.js';
import NotFound from './pages/NotFound.js';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/'>
          <Route index element={<Home />} />
          <Route path='register' element={<Register />} />
          <Route path='*' element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
