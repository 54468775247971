import React from 'react';

const UNIQUE = 'unique';
const MULTIPLE = 'multiple';

class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selectionType: UNIQUE };
  }

  uniqueSelectDay(month, day) {
    return () => {
      if (this.props.isInOrderMultipleDates(month, day)) {
        this.props.toggleOrderMultipleDate(month, day);
      }
      this.props.setOrderUniqueDate(month, day);
    };
  }

  multipleSelectDay(month, day) {
    return () => this.props.toggleOrderMultipleDate(month, day);
  }

  selectDay(month, day) {
    if (this.state.selectionType === UNIQUE) {
      return this.uniqueSelectDay(month, day);
    } else if (this.state.selectionType === MULTIPLE) {
      if (!this.props.isOrderUniqueDate(month, day)) {
        return this.multipleSelectDay(month, day);
      }
    }
    return null;
  }

  getWeekDays() {
    const WEEK_DAYS = ['LUN', 'MAR', 'MIE', 'JUE', 'VIE', 'SAB', 'DOM'];
    
    const days = WEEK_DAYS.map(day => {
      const PREFFIX = 'calendar-day-name';
      const KEY = PREFFIX + '-' + day;
      const CLASS_NAME = PREFFIX + (day === 'DOM' ? ' last-day' : '');
      return (
        <div className={CLASS_NAME} key={KEY}>
          <p>{day}</p>
        </div>
      );
    });

    return (
      <div className='calendar-week-days'>
        {days}
      </div>
    );
  }

  getMonthWeekDays(month, week) {
    return week.map((day, index) => {
      const PREFFIX = 'calendar-day';
      const KEY = PREFFIX + '-' + day + '-' + index;
      const CLASS_NAME = PREFFIX + (index === 6 ? ' last-day' : '');
      const DAY_LABEL = (day === -1) ? '' : day;

      let onClickDay = (day !== -1) ? this.selectDay(month, day) : null;
      
      let daySelection = '';
      if (day !== -1) {
        daySelection += 'selectable-day';
        if (this.props.isOrderUniqueDate(month, day)) {
          daySelection += ' unique-day';
        } else if (this.props.isInOrderMultipleDates(month, day) && this.props.isInOrderItems('food')) {
          daySelection += ' multiple-day';
        }
      }	

      return (
        <div key={KEY} className={CLASS_NAME}>
          <div className={daySelection} onClick={onClickDay}>
            <p>{DAY_LABEL}</p>
          </div>
        </div>
      );
    });
  }

  getMonthDays(month, year, firstDay, monthLength) {
    let days = []
    for (let i = 0; i < 5; i++) {
      let week = [];
      for (let j = 0; j < 7; j++) {
        week.push(-1);
      }
      days.push(week);
    }

    for (let i = 0, j = 0, k = firstDay; i < monthLength; i++) {
      days[j][k] = i + 1;
      k = (k + 1) % 7;
      j = (k === 0) ? j + 1 : j;
    }

    days = days.map((week, index) => {
      const PREFFIX = 'calendar-week-days';
      const KEY = PREFFIX + '-' + index;
      const CLASS_NAME = PREFFIX;

      return (
        <div key={KEY} className={CLASS_NAME}>
          {this.getMonthWeekDays(month, week)}
        </div>
      );
    });

    return (
      <div className='calendar-month-days'>
        {days}
      </div> 
    );
  }

  getMonth(month, year, firstDay, monthLength) {
    let weekDays = this.getWeekDays();
    let monthDays = this.getMonthDays(month, year, firstDay, monthLength);

    return (
      <div className='calendar-month col-md-6'>
        <div className='calendar-month-name'>
          <h3>{month}</h3>
        </div>
        {weekDays}
        {monthDays}
      </div>
    );
  }

  changeSelectionType(actionType) {
    return () => {
      this.setState({ selectionType: actionType });
    }
  }

  getButtonSelectionPanel(prefix) {
    const BUTTON_ACTION_TYPE = (prefix === 'butLeft') ? UNIQUE : MULTIPLE;
    const BUTTON_TEXT = this.props[prefix + 'Text'];
    const CONTAINER_CLASS = 'button-container col-md-' + this.props[prefix + 'Width'];
    const BUTTON_CLASS = (this.state.selectionType === BUTTON_ACTION_TYPE) ? 'selected' : '';
    
    return (
      <div className={CONTAINER_CLASS}>
        <button className={BUTTON_CLASS} onClick={this.changeSelectionType(BUTTON_ACTION_TYPE)}>
          {BUTTON_TEXT}
        </button>
      </div>
    );
  }

  getSelectionPanel() {
    let butLeft = this.props.butLeftVisible ? this.getButtonSelectionPanel('butLeft') : null;
    let butRight = this.props.butRightVisible ? this.getButtonSelectionPanel('butRight') : null;

    return (
      <div className='selection-panel row'>
        {butLeft}
        {butRight}
      </div>
    );
  }

  render() {
    let selectionPanel = this.getSelectionPanel();
    let currMonth = this.getMonth('NOVIEMBRE', 2016, 1, 30);
    let nextMonth = this.getMonth('DICIEMBRE', 2016, 3, 31);

    return (
      <div className='calendar row'>
        {selectionPanel}
        <div className='col-md-offset-6 vline' />
        <div className='calendar-container'>	
          {currMonth}
          {nextMonth}
        </div>
      </div>
    );
  }
}

export default Calendar;
