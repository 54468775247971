const orderBrands = [
  {
    id: 'dogchow',
    title: 'Dog Chow',
    subtitle: null,
    img: '/assets/foods/brands/brand_dogchow.png'
  },
  {
    id: 'dogourmet',
    title: 'Dogourmet',
    subtitle: null,
    img: '/assets/foods/brands/brand_dogourmet.png'
  },
  {
    id: 'nutranuggets',
    title: 'Nutra Nuggets',
    subtitle: null,
    img: '/assets/foods/brands/brand_nutranuggets.png'
  },
  {
    id: 'pedigree',
    title: 'Pedigree',
    subtitle: null,
    img: '/assets/foods/brands/brand_pedigree.png'
  },
  {
    id: 'proplan',
    title: 'Proplan',
    subtitle: null,
    img: '/assets/foods/brands/brand_proplan.png'
  },
  {
    id: 'tastewild',
    title: 'Taste of the Wild',
    subtitle: null,
    img: '/assets/foods/brands/brand_tastewild.png'
  }
];

export default orderBrands;