import { Link } from 'react-router-dom';
import Summary from './Summary.js';

import homebanner from '../images/homebanner.png';

function Step6(props) {
  return (
    <div className='container-fluid'>
      <div className='row checkout'>
        <div className='col-md-5'>
          <Summary
            orderItems={props.orderItems}
            orderBrand={props.orderBrand}
            orderSize={props.orderSize}
            orderAge={props.orderAge}
            orderFood={props.orderFood}
            orderUniqueDate={props.orderUniqueDate}
            orderMultipleDates={props.orderMultipleDates} />
        </div>
        <div className='col-md-7'>
          <div className='summary'>
            <div className='summary-container'>
              <h4>🌅 No disponible</h4>
              <div className='summary-block' >
                <p>Este proyecto ya no está siendo mantenido.</p>
                <p>Si te gustaría un servicio así o tienes alguna pregunta, puedes contactarme <a target='_blank' rel='noreferrer' href='https://jcbages.com/contact'>aquí</a>.</p>
                <p>También puedes volver a la página de inicio <Link to='/'>aquí</Link>.</p>
                <img src={homebanner} className='img-responsive rounded' alt='arionsito' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Step6;
