import React from 'react';

export default class Summary extends React.Component {
  getOrderSummary() {
    let finalList = [];

    for (const [id, item] of Object.entries(this.props.orderItems)) {
      if (item) {
        if (id === 'food') {
          finalList.push(this.props.orderFood);
        } else {
          finalList.push(item);
        }
      }
    }

    finalList = finalList.map((item, index) => {
      const ITEM_KEY = 'summary-' + index;

      let subtitle = item.subtitle;
      if (item.id !== 'snacks' && item.id !== 'toys') {
        let amount = this.multipleDaysUnique().length + 1;
        subtitle = `${amount} unidad${amount !== 1 ? 'es' : ''}`;
      }

      return (
        <li key={ITEM_KEY} className='summary-item row'>
          <div className='col-md-4'>
            <img src={item.img} alt='item' />
          </div>
          <div className='col-md-8'>
            <h5>{item.title}</h5>
            <h6>{subtitle}</h6>
          </div>
        </li>
      );
    });

    return (
      <div className='summary-block'>
        <h5>Tu paquete incluye</h5>
        <ul className='items-list'>
          {finalList}
        </ul>
      </div>
    );
  }

  getPetInfo() {
    let finalList = [this.props.orderSize, this.props.orderAge];

    finalList = finalList.map((item, index) => {
      const ITEM_KEY = 'summary-pet-' + index;

      return (
        <li key={ITEM_KEY} className='summary-item row'>
          <div className='col-md-4'>
            <img src={item.img} alt='item' />
          </div>
          <div className='col-md-8'>
            <h5>{item.title}</h5>
          </div>
        </li>
      );
    });

    return (
      <div className='summary-block'>
        <h5>Tu mascota es</h5>
        <ul className='items-list'>
          {finalList}
        </ul>
      </div>
    );
  }

  getPackageArrivalDate() {
    return (
      <div className='summary-block' >
        <h5>Tu paquete llega el día</h5>
        <ul className='items-list'>
          <li className='date-item'>
            <p>{this.props.orderUniqueDate.day + ' de cada mes'}</p>
          </li>
        </ul>
      </div>
    );
  }

  multipleDaysUnique() {
    const multipleDays = Object.values(this.props.orderMultipleDates)
      .map((date) => date.day)
      .filter((day) => day !== this.props.orderUniqueDate.day)
      .sort();

    return [...new Set(multipleDays)];
  }

  getFoodArrivalDates() {
    if (this.multipleDaysUnique().length > 0) {
      const multipleComponents = this.multipleDaysUnique().map(day => {
        const KEY_DAY = `food-arrival-${day}`;

        return (
          <li key={KEY_DAY} className='date-item'>
            <p>{`${day} de cada mes`}</p>
          </li>
        );
      });

      return (
        <div className='summary-block' >
          <h5>Recibirás comida extra los días</h5>
          <ul className='items-list'>
            {multipleComponents}
          </ul>
        </div>
      );
    }
    return null;
  }

  render() {
    let orderSummary = this.getOrderSummary();
    let petInfo = this.getPetInfo();
    let packageArrivalDate = this.getPackageArrivalDate();
    let foodArrivalDates = this.getFoodArrivalDates();

    return (
      <div className='summary'>
        <div className='summary-container'>
          <h4>Resumen de tu pedido</h4>
          {orderSummary}
          {petInfo}
          {packageArrivalDate}
          {foodArrivalDates}
        </div>
      </div>
    );
  }
}