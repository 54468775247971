import { useState } from 'react';
import { Link } from 'react-router-dom';

import '../css/navbar.css';
import '../css/calendar.css';
import '../css/items.css';
import '../css/step.css';
import '../css/summary.css';

import logo from '../images/logo.png';

import Step1 from '../components/Step1.js';
import Step2 from '../components/Step2.js';
import Step3 from '../components/Step3.js';
import Step4 from '../components/Step4.js';
import Step5 from '../components/Step5.js';
import Step6 from '../components/Step6.js';

const subtitles = [
  '¿Qué quieres incluir en tu paquete?',
  '¿Qué marca de comida prefieres?',
  'Algunos datos de tu mascota...',
  'Elige la comida que quieres...',
  'Elige la fecha en la que quieres que llegue tu paquete',
  'Finaliza tu inscripción'
];

function Register() {
  const [orderItems, setOrderItems] = useState({});
  const isInOrderItems = (id) => !!orderItems[id];
  const toggleOrderItem = (item) => {
    const newOrderItems = {...orderItems};
    if (newOrderItems[item.id]) {
      delete newOrderItems[item.id];
    } else {
      newOrderItems[item.id] = item;
    }
    setOrderItems(newOrderItems);
  }

  const [orderBrand, setOrderBrand] = useState(null);
  const isOrderBrand = (id) => orderBrand && orderBrand.id === id;

  const [orderSize, setOrderSize] = useState(null);
  const isOrderSize = (id) => orderSize && orderSize.id === id;

  const [orderAge, setOrderAge] = useState(null);
  const isOrderAge = (id) => orderAge && orderAge.id === id;

  const [orderFood, setOrderFood] = useState(null);
  const isOrderFood = (id) => orderFood && orderFood.id === id;

  const [orderUniqueDate, _setOrderUniqueDate] = useState(null);
  const isOrderUniqueDate = (month, day) => orderUniqueDate && orderUniqueDate.id === `${month}/${day}`;
  const setOrderUniqueDate = (month, day) => _setOrderUniqueDate({id: `${month}/${day}`, month: month, day: day});

  const [orderMultipleDates, setOrderMultipleDates] = useState({});
  const isInOrderMultipleDates = (month, day) => !!orderMultipleDates[`${month}/${day}`];
  const toggleOrderMultipleDate = (month, day) => {
    const id = `${month}/${day}`;
    const newOrderMultipleDates = {...orderMultipleDates};
    if (newOrderMultipleDates[id]) {
      delete newOrderMultipleDates[id];
    } else {
      newOrderMultipleDates[id] = {id: id, month: month, day: day};
    }
    setOrderMultipleDates(newOrderMultipleDates);
  }

  const [stepNumber, setStepNumber] = useState(1);
  const [renderStep, setRenderStep] = useState(1);

  const increaseStep = () => {
    if (stepNumber === 1 && !isInOrderItems('food')) {
      setStepNumber(3);
    } else if (stepNumber === 3 && !isInOrderItems('food')) {
      setStepNumber(5);
    } else {
      setStepNumber(stepNumber + 1);
    }

    setRenderStep(renderStep + 1);
  };

  const decreaseStep = () => {
    if (stepNumber === 5 && !isInOrderItems('food')) {
      setStepNumber(3);
    } else if (stepNumber === 3 && !isInOrderItems('food')) {
      setStepNumber(1);
    } else {
      setStepNumber(stepNumber - 1);
    }

    setRenderStep(renderStep - 1);
  };

  const valid = () => {
    switch (stepNumber) {
      case 1:
        return Object.keys(orderItems).length > 0;
      case 2:
        return orderBrand !== null;
      case 3:
        return orderAge !== null && orderSize !== null;
      case 4:
        return orderFood !== null;
      case 5:
        return orderUniqueDate !== null;
      default:
        return false;
    }
  }

  const getStep = () => {
    switch (stepNumber) {
      case 1:
        return (
          <Step1
            isInOrderItems={isInOrderItems}
            toggleOrderItem={toggleOrderItem} />
        );
      case 2:
        return (
          <Step2
            isOrderBrand={isOrderBrand}
            setOrderBrand={setOrderBrand} />
        );
      case 3:
        return (
          <Step3
            isOrderSize={isOrderSize}
            setOrderSize={setOrderSize}
            isOrderAge={isOrderAge}
            setOrderAge={setOrderAge} />
        );
      case 4:
        return (
          <Step4
            orderAge={orderAge}
            orderSize={orderSize}
            orderBrand={orderBrand}
            isOrderFood={isOrderFood}
            setOrderFood={setOrderFood} />
        );
      case 5:
        return (
          <Step5
            isInOrderItems={isInOrderItems}
            isOrderUniqueDate={isOrderUniqueDate}
            isInOrderMultipleDates={isInOrderMultipleDates}
            setOrderUniqueDate={setOrderUniqueDate}
            toggleOrderMultipleDate={toggleOrderMultipleDate} />
        );
      case 6:
        return (
          <Step6
            orderItems={orderItems}
            orderBrand={orderBrand}
            orderSize={orderSize}
            orderAge={orderAge}
            orderFood={orderFood}
            orderUniqueDate={orderUniqueDate}
            orderMultipleDates={orderMultipleDates} />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <div className='container-fluid'>
        <div className='navbar-container row'>
          <div className='navbar-button-container col-md-4'>
            {
              stepNumber === 1 &&
              <Link to='/'>
                <button type='button' className='button-left'>Inicio</button>
              </Link>
            }
            {
              stepNumber !== 1 &&
              <button type='button' onClick={decreaseStep} className='button-left'>Volver</button>
            }
          </div>
          <div className='navbar-title-container col-md-4'>
            <img src={logo} alt='logo' />
          </div>
          <div className='navbar-button-container col-md-4'></div>
        </div>
      </div>

      <div className='header-container container-fluid'>
        <div className='title-container row'>
          <div className='col-md-10 col-md-offset-2'>
            <h2>Paso {renderStep}</h2>
          </div>
        </div>
        <div className='subtitle-container row'>
          <div className='col-md-12'>
            <h2>{subtitles[stepNumber - 1]}</h2>
          </div>
        </div>
      </div>

      {getStep()}

      {
        stepNumber < 6 &&
        <div className='container-fluid'>
          <div className='continue-button-container row'>
            <div className='col-md-4 col-md-offset-4'>
              <button onClick={increaseStep} disabled={!valid()}>
                Continuar	
              </button>
            </div>
          </div>
        </div>
      }

      {
        stepNumber === 1 &&
        <div className='container-fluid'>
          <div className='footer-container row'>
            <div className='col-md-12'>
              <h3>
                Recuerda que mensualmente te enviamos
                <br />
                🎁 🎁 🎁 un regalo sorpresa 🎁 🎁 🎁
              </h3>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default Register;
