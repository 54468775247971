
const orderFoods = JSON.parse(`[
  {
    "brand": "dogourmet",
    "brand_name": "Dogourmet",
    "name": "Cachorros Carne y Cereales",
    "weight": [
      "8kg",
      "16kg",
      "22kg"
    ],
    "img": "/assets/foods/dogourmet/pic_paq_carnecereales.png",
    "age": [
      "cachorro"
    ],
    "size": [
      "peque\u00f1a",
      "mediana",
      "grande"
    ],
    "price": 10000,
    "id": "$dogourmet-Cachorros Carne y Cereales",
    "title": "Cachorros Carne y Cereales",
    "subtitle": "(8kg - 16kg - 22kg)"
  },
  {
    "brand": "dogourmet",
    "brand_name": "Dogourmet",
    "name": "Carne a la Parrilla",
    "weight": [
      "8kg",
      "16kg",
      "22kg"
    ],
    "img": "/assets/foods/dogourmet/pic_paq_carneparrilla.png",
    "age": [
      "adulto"
    ],
    "size": [
      "peque\u00f1a",
      "mediana",
      "grande"
    ],
    "price": 10000,
    "id": "$dogourmet-Carne a la Parrilla",
    "title": "Carne a la Parrilla",
    "subtitle": "(8kg - 16kg - 22kg)"
  },
  {
    "brand": "dogourmet",
    "brand_name": "Dogourmet",
    "name": "Pollo a la Brasa",
    "weight": [
      "8kg",
      "16kg",
      "22kg"
    ],
    "img": "/assets/foods/dogourmet/pic_paq_pollo.png",
    "age": [
      "adulto"
    ],
    "size": [
      "peque\u00f1a",
      "mediana",
      "grande"
    ],
    "price": 10000,
    "id": "$dogourmet-Pollo a la Brasa",
    "title": "Pollo a la Brasa",
    "subtitle": "(8kg - 16kg - 22kg)"
  },
  {
    "brand": "proplan",
    "brand_name": "Purina Pro Plan",
    "name": "Puppy Complete con Optistart Plus",
    "weight": [
      "1kg",
      "3kg",
      "7.5kg",
      "15kg"
    ],
    "img": "/assets/foods/proplan/PP_puppy_complete.png",
    "age": [
      "cachorro"
    ],
    "size": [
      "mediana"
    ],
    "price": 10000,
    "id": "$proplan-Puppy Complete con Optistart Plus",
    "title": "Puppy Complete con Optistart Plus",
    "subtitle": "(1kg - 3kg - 7.5kg - 15kg)"
  },
  {
    "brand": "proplan",
    "brand_name": "Purina Pro Plan",
    "name": "Puppy Large Breed con Optistart Plus",
    "weight": [
      "3kg",
      "15kg"
    ],
    "img": "/assets/foods/proplan/PP_puppy_large_breed.png",
    "age": [
      "cachorro"
    ],
    "size": [
      "grande"
    ],
    "price": 10000,
    "id": "$proplan-Puppy Large Breed con Optistart Plus",
    "title": "Puppy Large Breed con Optistart Plus",
    "subtitle": "(3kg - 15kg)"
  },
  {
    "brand": "proplan",
    "brand_name": "Purina Pro Plan",
    "name": "Puppy Small Breed con Optistart Plus",
    "weight": [
      "1kg",
      "3kg",
      "7.5kg"
    ],
    "img": "/assets/foods/proplan/PP_puppy_small_breed.png",
    "age": [
      "cachorro"
    ],
    "size": [
      "peque\u00f1a"
    ],
    "price": 10000,
    "id": "$proplan-Puppy Small Breed con Optistart Plus",
    "title": "Puppy Small Breed con Optistart Plus",
    "subtitle": "(1kg - 3kg - 7.5kg)"
  },
  {
    "brand": "proplan",
    "brand_name": "Purina Pro Plan",
    "name": "Adult Complete con Optilife Triple Acci\u00f3n",
    "weight": [
      "1kg",
      "3kg",
      "6.5kg",
      "15kg"
    ],
    "img": "/assets/foods/proplan/PP_adult_complete.png",
    "age": [
      "adulto"
    ],
    "size": [
      "mediana"
    ],
    "price": 10000,
    "id": "$proplan-Adult Complete con Optilife Triple Acci\u00f3n",
    "title": "Adult Complete con Optilife Triple Acci\u00f3n",
    "subtitle": "(1kg - 3kg - 6.5kg - 15kg)"
  },
  {
    "brand": "proplan",
    "brand_name": "Purina Pro Plan",
    "name": "Adult Large Breed con Optilife Triple Acci\u00f3n",
    "weight": [
      "3kg",
      "15kg"
    ],
    "img": "/assets/foods/proplan/PP_adult_large_breed.png",
    "age": [
      "adulto"
    ],
    "size": [
      "grande"
    ],
    "price": 10000,
    "id": "$proplan-Adult Large Breed con Optilife Triple Acci\u00f3n",
    "title": "Adult Large Breed con Optilife Triple Acci\u00f3n",
    "subtitle": "(3kg - 15kg)"
  },
  {
    "brand": "proplan",
    "brand_name": "Purina Pro Plan",
    "name": "Adult Small Breed con Optilife Triple Acci\u00f3n",
    "weight": [
      "1kg",
      "3kg",
      "7.5kg"
    ],
    "img": "/assets/foods/proplan/PP_adult_small_breed.png",
    "age": [
      "adulto"
    ],
    "size": [
      "peque\u00f1a"
    ],
    "price": 10000,
    "id": "$proplan-Adult Small Breed con Optilife Triple Acci\u00f3n",
    "title": "Adult Small Breed con Optilife Triple Acci\u00f3n",
    "subtitle": "(1kg - 3kg - 7.5kg)"
  },
  {
    "brand": "proplan",
    "brand_name": "Purina Pro Plan",
    "name": "Adult Sensitive Skin con Optiderma",
    "weight": [
      "3kg",
      "15kg"
    ],
    "img": "/assets/foods/proplan/PP_adult_sensitive_skin.png",
    "age": [
      "adulto"
    ],
    "size": [
      "peque\u00f1a",
      "mediana",
      "grande"
    ],
    "price": 10000,
    "id": "$proplan-Adult Sensitive Skin con Optiderma",
    "title": "Adult Sensitive Skin con Optiderma",
    "subtitle": "(3kg - 15kg)"
  },
  {
    "brand": "proplan",
    "brand_name": "Purina Pro Plan",
    "name": "Reduced Calorie con Optifit",
    "weight": [
      "3kg",
      "15kg"
    ],
    "img": "/assets/foods/proplan/PP_reduce_calorie.png",
    "age": [
      "adulto"
    ],
    "size": [
      "peque\u00f1a",
      "mediana",
      "grande"
    ],
    "price": 10000,
    "id": "$proplan-Reduced Calorie con Optifit",
    "title": "Reduced Calorie con Optifit",
    "subtitle": "(3kg - 15kg)"
  },
  {
    "brand": "proplan",
    "brand_name": "Purina Pro Plan",
    "name": "Delicate Small Breed con Optifortis",
    "weight": [
      "1kg",
      "3kg"
    ],
    "img": "/assets/foods/proplan/PP_delicate_small_breed.png",
    "age": [
      "adulto"
    ],
    "size": [
      "peque\u00f1a"
    ],
    "price": 10000,
    "id": "$proplan-Delicate Small Breed con Optifortis",
    "title": "Delicate Small Breed con Optifortis",
    "subtitle": "(1kg - 3kg)"
  },
  {
    "brand": "proplan",
    "brand_name": "Purina Pro Plan",
    "name": "Exigent Small Breed con Optienrich",
    "weight": [
      "1kg",
      "3kg"
    ],
    "img": "/assets/foods/proplan/PP_exigent_small_breed.png",
    "age": [
      "adulto"
    ],
    "size": [
      "peque\u00f1a"
    ],
    "price": 10000,
    "id": "$proplan-Exigent Small Breed con Optienrich",
    "title": "Exigent Small Breed con Optienrich",
    "subtitle": "(1kg - 3kg)"
  },
  {
    "brand": "proplan",
    "brand_name": "Purina Pro Plan",
    "name": "Adult 7+ Complete con Optiage",
    "weight": [
      "3kg",
      "10kg",
      "15kg"
    ],
    "img": "/assets/foods/proplan/PP_adult_7plus.png",
    "age": [
      "adulto"
    ],
    "size": [
      "mediana",
      "grande"
    ],
    "price": 10000,
    "id": "$proplan-Adult 7+ Complete con Optiage",
    "title": "Adult 7+ Complete con Optiage",
    "subtitle": "(3kg - 10kg - 15kg)"
  },
  {
    "brand": "proplan",
    "brand_name": "Purina Pro Plan",
    "name": "Adult 7+ Small Breed con Optiage",
    "weight": [
      "3kg",
      "10kg",
      "15kg"
    ],
    "img": "/assets/foods/proplan/PP_adult_7plus_small_breed.png",
    "age": [
      "adulto"
    ],
    "size": [
      "peque\u00f1a"
    ],
    "price": 10000,
    "id": "$proplan-Adult 7+ Small Breed con Optiage",
    "title": "Adult 7+ Small Breed con Optiage",
    "subtitle": "(3kg - 10kg - 15kg)"
  },
  {
    "brand": "dogchow",
    "brand_name": "Purina Dog Chow",
    "name": "Cachorros Razas Peque\u00f1as",
    "weight": [
      "1,5kg",
      "3kg",
      "21kg"
    ],
    "img": "/assets/foods/dogchow/pack_cachorros_peq_interna.png",
    "age": [
      "cachorro"
    ],
    "size": [
      "peque\u00f1a"
    ],
    "price": 10000,
    "id": "$dogchow-Cachorros Razas Peque\u00f1as",
    "title": "Cachorros Razas Peque\u00f1as",
    "subtitle": "(1,5kg - 3kg - 21kg)"
  },
  {
    "brand": "dogchow",
    "brand_name": "Purina Dog Chow",
    "name": "Cachorros Razas Medianas y Grandes",
    "weight": [
      "1,5kg",
      "3kg",
      "8kg",
      "15kg",
      "21kg"
    ],
    "img": "/assets/foods/dogchow/pack_cachorros_interna.png",
    "age": [
      "cachorro"
    ],
    "size": [
      "mediana",
      "grande"
    ],
    "price": 10000,
    "id": "$dogchow-Cachorros Razas Medianas y Grandes",
    "title": "Cachorros Razas Medianas y Grandes",
    "subtitle": "(1,5kg - 3kg - 8kg - 15kg - 21kg)"
  },
  {
    "brand": "dogchow",
    "brand_name": "Purina Dog Chow",
    "name": "Essentials Adulto",
    "weight": [
      "1,5kg",
      "3kg",
      "7,8kg"
    ],
    "img": "/assets/foods/dogchow/herbal_essences_adulto_pack.png",
    "age": [
      "adulto"
    ],
    "size": [
      "peque\u00f1a",
      "mediana",
      "grande"
    ],
    "price": 10000,
    "id": "$dogchow-Essentials Adulto",
    "title": "Essentials Adulto",
    "subtitle": "(1,5kg - 3kg - 7,8kg)"
  },
  {
    "brand": "dogchow",
    "brand_name": "Purina Dog Chow",
    "name": "Essentials Cachorros",
    "weight": [
      "1,5kg",
      "3kg",
      "7,8kg"
    ],
    "img": "/assets/foods/dogchow/herbal_essences_puppy_pack.png",
    "age": [
      "cachorro"
    ],
    "size": [
      "peque\u00f1a",
      "mediana",
      "grande"
    ],
    "price": 10000,
    "id": "$dogchow-Essentials Cachorros",
    "title": "Essentials Cachorros",
    "subtitle": "(1,5kg - 3kg - 7,8kg)"
  },
  {
    "brand": "dogchow",
    "brand_name": "Purina Dog Chow",
    "name": "Adultos Razas Peque\u00f1as",
    "weight": [
      "1,5kg",
      "3kg",
      "8kg",
      "21kg"
    ],
    "img": "/assets/foods/dogchow/pack_adultos_peq_interna.png",
    "age": [
      "adulto"
    ],
    "size": [
      "peque\u00f1a"
    ],
    "price": 10000,
    "id": "$dogchow-Adultos Razas Peque\u00f1as",
    "title": "Adultos Razas Peque\u00f1as",
    "subtitle": "(1,5kg - 3kg - 8kg - 21kg)"
  },
  {
    "brand": "dogchow",
    "brand_name": "Purina Dog Chow",
    "name": "Adultos Razas Medianas y Grandes",
    "weight": [
      "1,5kg",
      "3kg",
      "8kg",
      "15kg",
      "21kg"
    ],
    "img": "/assets/foods/dogchow/pack_adultos_interna.png",
    "age": [
      "adulto"
    ],
    "size": [
      "mediana",
      "grande"
    ],
    "price": 10000,
    "id": "$dogchow-Adultos Razas Medianas y Grandes",
    "title": "Adultos Razas Medianas y Grandes",
    "subtitle": "(1,5kg - 3kg - 8kg - 15kg - 21kg)"
  },
  {
    "brand": "dogchow",
    "brand_name": "Purina Dog Chow",
    "name": "Edad Madura",
    "weight": [
      "3,75kg",
      "7,5kg"
    ],
    "img": "/assets/foods/dogchow/pack_madura_interna.png",
    "age": [
      "adulto"
    ],
    "size": [
      "peque\u00f1a",
      "mediana",
      "grande"
    ],
    "price": 10000,
    "id": "$dogchow-Edad Madura",
    "title": "Edad Madura",
    "subtitle": "(3,75kg - 7,5kg)"
  },
  {
    "brand": "tastewild",
    "brand_name": "Taste of the Wild",
    "name": "Puppy con Salm\u00f3n Ahumado",
    "weight": [
      "2kg",
      "6kg",
      "13kg"
    ],
    "img": "/assets/foods/tastewild/taste_of_the_wild_pacific_puppy.png",
    "age": [
      "cachorro"
    ],
    "size": [
      "peque\u00f1a",
      "mediana",
      "grande"
    ],
    "price": 10000,
    "id": "$tastewild-Puppy con Salm\u00f3n Ahumado",
    "title": "Puppy con Salm\u00f3n Ahumado",
    "subtitle": "(2kg - 6kg - 13kg)"
  },
  {
    "brand": "tastewild",
    "brand_name": "Taste of the Wild",
    "name": "Puppy con Carne de Bisonte y Venado Asados",
    "weight": [
      "2kg",
      "6kg",
      "13kg"
    ],
    "img": "/assets/foods/tastewild/RoastedHPPuppy_Web.png",
    "age": [
      "cachorro"
    ],
    "size": [
      "peque\u00f1a",
      "mediana",
      "grande"
    ],
    "price": 10000,
    "id": "$tastewild-Puppy con Carne de Bisonte y Venado Asados",
    "title": "Puppy con Carne de Bisonte y Venado Asados",
    "subtitle": "(2kg - 6kg - 13kg)"
  },
  {
    "brand": "tastewild",
    "brand_name": "Taste of the Wild",
    "name": "Carne de Bisonte y Venado Asado",
    "weight": [
      "2kg",
      "6kg",
      "12kg"
    ],
    "img": "/assets/foods/tastewild/RoastedHP_Web.png",
    "age": [
      "adulto"
    ],
    "size": [
      "peque\u00f1a",
      "mediana",
      "grande"
    ],
    "price": 10000,
    "id": "$tastewild-Carne de Bisonte y Venado Asado",
    "title": "Carne de Bisonte y Venado Asado",
    "subtitle": "(2kg - 6kg - 12kg)"
  },
  {
    "brand": "tastewild",
    "brand_name": "Taste of the Wild",
    "name": "Pato Asado",
    "weight": [
      "2kg",
      "6kg",
      "12kg"
    ],
    "img": "/assets/foods/tastewild/taste_of_the_wild_wetlands.png",
    "age": [
      "adulto"
    ],
    "size": [
      "peque\u00f1a",
      "mediana",
      "grande"
    ],
    "price": 10000,
    "id": "$tastewild-Pato Asado",
    "title": "Pato Asado",
    "subtitle": "(2kg - 6kg - 12kg)"
  },
  {
    "brand": "tastewild",
    "brand_name": "Taste of the Wild",
    "name": "Salm\u00f3n Ahumado",
    "weight": [
      "2kg",
      "6kg",
      "12kg"
    ],
    "img": "/assets/foods/tastewild/taste_of_the_wild_pacific_stream.png",
    "age": [
      "adulto"
    ],
    "size": [
      "peque\u00f1a",
      "mediana",
      "grande"
    ],
    "price": 10000,
    "id": "$tastewild-Salm\u00f3n Ahumado",
    "title": "Salm\u00f3n Ahumado",
    "subtitle": "(2kg - 6kg - 12kg)"
  },
  {
    "brand": "tastewild",
    "brand_name": "Taste of the Wild",
    "name": "Carne de Venado y Garbanzos Especial para Razas Peque\u00f1as",
    "weight": [
      "2,3kg",
      "6,8kg",
      "12,4kg"
    ],
    "img": "/assets/foods/tastewild/AppValley.png",
    "age": [
      "adulto"
    ],
    "size": [
      "peque\u00f1a"
    ],
    "price": 10000,
    "id": "$tastewild-Carne de Venado y Garbanzos Especial para Razas Peque\u00f1as",
    "title": "Carne de Venado y Garbanzos Especial para Razas Peque\u00f1as",
    "subtitle": "(2,3kg - 6,8kg - 12,4kg)"
  },
  {
    "brand": "tastewild",
    "brand_name": "Taste of the Wild",
    "name": "Carnde de Venado y Legumbres",
    "weight": [
      "2,3kg",
      "6,8kg",
      "12,4kg"
    ],
    "img": "/assets/foods/tastewild/PineForest.png",
    "age": [
      "adulto"
    ],
    "size": [
      "peque\u00f1a",
      "mediana",
      "grande"
    ],
    "price": 10000,
    "id": "$tastewild-Carnde de Venado y Legumbres",
    "title": "Carnde de Venado y Legumbres",
    "subtitle": "(2,3kg - 6,8kg - 12,4kg)"
  },
  {
    "brand": "tastewild",
    "brand_name": "Taste of the Wild",
    "name": "Jabal\u00ed",
    "weight": [
      "2kg",
      "6kg",
      "12kg"
    ],
    "img": "/assets/foods/tastewild/taste_of_the_wild_southwest_canyon.png",
    "age": [
      "adulto"
    ],
    "size": [
      "peque\u00f1a",
      "mediana",
      "grande"
    ],
    "price": 10000,
    "id": "$tastewild-Jabal\u00ed",
    "title": "Jabal\u00ed",
    "subtitle": "(2kg - 6kg - 12kg)"
  },
  {
    "brand": "tastewild",
    "brand_name": "Taste of the Wild",
    "name": "Cordero Asado",
    "weight": [
      "2kg",
      "6kg",
      "12kg"
    ],
    "img": "/assets/foods/tastewild/taste_of_the_wild_sierra_mountain.png",
    "age": [
      "adulto"
    ],
    "size": [
      "peque\u00f1a",
      "mediana",
      "grande"
    ],
    "price": 10000,
    "id": "$tastewild-Cordero Asado",
    "title": "Cordero Asado",
    "subtitle": "(2kg - 6kg - 12kg)"
  },
  {
    "brand": "nutranuggets",
    "brand_name": "Nutra Nuggets",
    "name": "Puppy",
    "weight": [
      "3kg",
      "7,5kg",
      "15kg"
    ],
    "img": "/assets/foods/nutranuggets/Puppy.png",
    "age": [
      "cachorro"
    ],
    "size": [
      "peque\u00f1a",
      "mediana",
      "grande"
    ],
    "price": 10000,
    "id": "$nutranuggets-Puppy",
    "title": "Puppy",
    "subtitle": "(3kg - 7,5kg - 15kg)"
  },
  {
    "brand": "nutranuggets",
    "brand_name": "Nutra Nuggets",
    "name": "Puppy Large Breed",
    "weight": [
      "3kg",
      "15kg"
    ],
    "img": "/assets/foods/nutranuggets/LBPuppy.png",
    "age": [
      "cachorro"
    ],
    "size": [
      "grande"
    ],
    "price": 10000,
    "id": "$nutranuggets-Puppy Large Breed",
    "title": "Puppy Large Breed",
    "subtitle": "(3kg - 15kg)"
  },
  {
    "brand": "nutranuggets",
    "brand_name": "Nutra Nuggets",
    "name": "Maintenance for Dogs",
    "weight": [
      "3kg",
      "7,5kg",
      "15kg",
      "18kg"
    ],
    "img": "/assets/foods/nutranuggets/MaintenanceDog.png",
    "age": [
      "adulto"
    ],
    "size": [
      "peque\u00f1a",
      "mediana",
      "grande"
    ],
    "price": 10000,
    "id": "$nutranuggets-Maintenance for Dogs",
    "title": "Maintenance for Dogs",
    "subtitle": "(3kg - 7,5kg - 15kg - 18kg)"
  },
  {
    "brand": "nutranuggets",
    "brand_name": "Nutra Nuggets",
    "name": "Cordero y Arroz",
    "weight": [
      "3kg",
      "7,5kg",
      "15kg",
      "18kg"
    ],
    "img": "/assets/foods/nutranuggets/LMR.png",
    "age": [
      "adulto"
    ],
    "size": [
      "peque\u00f1a",
      "mediana",
      "grande"
    ],
    "price": 10000,
    "id": "$nutranuggets-Cordero y Arroz",
    "title": "Cordero y Arroz",
    "subtitle": "(3kg - 7,5kg - 15kg - 18kg)"
  },
  {
    "brand": "nutranuggets",
    "brand_name": "Nutra Nuggets",
    "name": "Performance for Dogs",
    "weight": [
      "3kg",
      "7,5kg",
      "15kg",
      "18kg"
    ],
    "img": "/assets/foods/nutranuggets/PerformanceDog.png",
    "age": [
      "adulto"
    ],
    "size": [
      "peque\u00f1a",
      "mediana",
      "grande"
    ],
    "price": 10000,
    "id": "$nutranuggets-Performance for Dogs",
    "title": "Performance for Dogs",
    "subtitle": "(3kg - 7,5kg - 15kg - 18kg)"
  },
  {
    "brand": "nutranuggets",
    "brand_name": "Nutra Nuggets",
    "name": "Professional for Dogs",
    "weight": [
      "3kg",
      "7,5kg",
      "15kg"
    ],
    "img": "/assets/foods/nutranuggets/ProDog.png",
    "age": [
      "adulto"
    ],
    "size": [
      "peque\u00f1a",
      "mediana",
      "grande"
    ],
    "price": 10000,
    "id": "$nutranuggets-Professional for Dogs",
    "title": "Professional for Dogs",
    "subtitle": "(3kg - 7,5kg - 15kg)"
  },
  {
    "brand": "nutranuggets",
    "brand_name": "Nutra Nuggets",
    "name": "Lite/Senior for Dogs",
    "weight": [
      "3kg",
      "7,5kg",
      "15kg"
    ],
    "img": "/assets/foods/nutranuggets/SeniorDog.png",
    "age": [
      "adulto"
    ],
    "size": [
      "peque\u00f1a",
      "mediana",
      "grande"
    ],
    "price": 10000,
    "id": "$nutranuggets-Lite/Senior for Dogs",
    "title": "Lite/Senior for Dogs",
    "subtitle": "(3kg - 7,5kg - 15kg)"
  },
  {
    "brand": "nutranuggets",
    "brand_name": "Nutra Nuggets",
    "name": "Large Breed Adult Dog",
    "weight": [
      "3kg",
      "15kg",
      "18kg"
    ],
    "img": "/assets/foods/nutranuggets/LBDog.png",
    "age": [
      "adulto"
    ],
    "size": [
      "grande"
    ],
    "price": 10000,
    "id": "$nutranuggets-Large Breed Adult Dog",
    "title": "Large Breed Adult Dog",
    "subtitle": "(3kg - 15kg - 18kg)"
  },
  {
    "brand": "pedigree",
    "brand_name": "Pedigree",
    "name": "Adulto Senior",
    "weight": [
      "1,8kg"
    ],
    "img": "/assets/foods/pedigree/adulto_senior2.png",
    "age": [
      "adulto"
    ],
    "size": [
      "peque\u00f1a",
      "mediana",
      "grande"
    ],
    "price": 10000,
    "id": "$pedigree-Adulto Senior",
    "title": "Adulto Senior",
    "subtitle": "(1,8kg)"
  },
  {
    "brand": "pedigree",
    "brand_name": "Pedigree",
    "name": "Adulto Nutrici\u00f3n Completa",
    "weight": [
      "1kg",
      "2kg",
      "4kg",
      "8kg",
      "17kg",
      "22kg"
    ],
    "img": "/assets/foods/pedigree/adulto_nutricion_completa.png",
    "age": [
      "adulto"
    ],
    "size": [
      "mediana",
      "grande"
    ],
    "price": 10000,
    "id": "$pedigree-Adulto Nutrici\u00f3n Completa",
    "title": "Adulto Nutrici\u00f3n Completa",
    "subtitle": "(1kg - 2kg - 4kg - 8kg - 17kg - 22kg)"
  },
  {
    "brand": "pedigree",
    "brand_name": "Pedigree",
    "name": "Adulto Razas Peque\u00f1as",
    "weight": [
      "2kg",
      "4kg",
      "8kg",
      "17kg"
    ],
    "img": "/assets/foods/pedigree/adulto_razas_pequenas.png",
    "age": [
      "adulto"
    ],
    "size": [
      "peque\u00f1a"
    ],
    "price": 10000,
    "id": "$pedigree-Adulto Razas Peque\u00f1as",
    "title": "Adulto Razas Peque\u00f1as",
    "subtitle": "(2kg - 4kg - 8kg - 17kg)"
  },
  {
    "brand": "pedigree",
    "brand_name": "Pedigree",
    "name": "Cachorro Etapa 1",
    "weight": [
      "1kg",
      "2kg",
      "4kg",
      "8kg",
      "17kg",
      "22kg"
    ],
    "img": "/assets/foods/pedigree/cachorro_etapa1.png",
    "age": [
      "cachorro"
    ],
    "size": [
      "peque\u00f1a",
      "mediana",
      "grande"
    ],
    "price": 10000,
    "id": "$pedigree-Cachorro Etapa 1",
    "title": "Cachorro Etapa 1",
    "subtitle": "(1kg - 2kg - 4kg - 8kg - 17kg - 22kg)"
  },
  {
    "brand": "pedigree",
    "brand_name": "Pedigree",
    "name": "Cachorro Etapa 2",
    "weight": [
      "2kg",
      "4kg",
      "8kg",
      "17kg"
    ],
    "img": "/assets/foods/pedigree/cachorro_etapa2.png",
    "age": [
      "cachorro"
    ],
    "size": [
      "peque\u00f1a",
      "mediana",
      "grande"
    ],
    "price": 10000,
    "id": "$pedigree-Cachorro Etapa 2",
    "title": "Cachorro Etapa 2",
    "subtitle": "(2kg - 4kg - 8kg - 17kg)"
  }
]`);

export default orderFoods;
