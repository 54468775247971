import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import './css/main.css';
import './css/popup.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <div id='archived'>
      <p>
        🌅 This project has sailed into the sunset and no longer receives updates. For any inquiries, don't hesitate to drop a line right over <a target='_blank' rel='noreferrer' href='https://jcbages.com/contact'>here</a>
      </p>
    </div>
    <App />
  </React.StrictMode>
);
