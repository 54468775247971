import Items from './Items.js';
import orderBrands from '../data/orderBrands.js';

function Step2(props) {
  return (
    <div className='container-fluid'>
      <Items
        items={orderBrands}
        columnWidth={3}
        isSelected={props.isOrderBrand}
        onClick={props.setOrderBrand} />
    </div>
  );
}

export default Step2;
