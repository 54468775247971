import Items from './Items.js';
import orderItems from '../data/orderItems.js';

function Step1(props) {
  return (
    <div className='container-fluid'>
      <Items
        items={orderItems}
        columnWidth={4}
        isSelected={props.isInOrderItems}
        onClick={props.toggleOrderItem} />
    </div>
  );
}

export default Step1;
