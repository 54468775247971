import Item from './Item.js';

function Items(props) {
  const items = props.items.map((item, index) => (
    <Item
      key={item.id}
      item={item}
      index={index}
      isSelected={props.isSelected}
      itemExtraClassName={props.itemExtraClassName}
      columnWidth={props.columnWidth}
      columnOffset={props.columnOffset}
      onClick={props.onClick} />
  ));

  return (
    <div className='row'>
      <div className='col-md-12'>
        {
          props.title &&
          <div className='main-title-container row'>
            <h4>{props.title}</h4>
          </div>
        }
        <div className='items-container row'>
          {items}
        </div>
      </div>
    </div>
  );
}

export default Items;
