import orderAges from '../data/orderAges.js';
import orderSizes from '../data/orderSizes.js';
import Items from './Items.js';

function Step3(props) {
  return (
    <div className='container-fluid'>
      <Items
        items={orderSizes}
        columnWidth={4}
        title='¿Qué tamaño de raza es?'
        isSelected={props.isOrderSize}
        onClick={props.setOrderSize} />
      
      <Items
        items={orderAges}
        columnWidth={4}
        columnOffset={2}
        title='¿En que edad se encuentra?'
        isSelected={props.isOrderAge}
        onClick={props.setOrderAge} />
    </div>
  );
}

export default Step3;
