import { Link } from 'react-router-dom';

import '../css/home.css';
import '../css/navbar.css';

import logowhite from '../images/logowhite.png';
import logo from '../images/logo.png';
import step1 from '../images/step1.png';
import step2 from '../images/step2.png';
import step3 from '../images/step3.png';
import step4 from '../images/step4.png';
import dogsbanner from '../images/dogsbanner.png';

function knowMore() {
  const element = document.getElementById('tutorial');
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
}

function Home() {
  return (
    <div>
      <div className='banner-container'>
        <div className='blackscreen-container'>
          <div className='container-fluid'>
            <div className='logo-container row'>
              <div className='col-md-4 col-md-offset-4'>
                <img src={logowhite} alt='logo' />
              </div>
            </div>
            <div className='slogan-container row'>
              <div className='col-md-8 col-md-offset-2'>
                <h3>
                  La suscripción mensual que hará feliz
                  a tu mascota
                </h3>
              </div>
            </div>
            <div className='information-buttons-container row'>
              <div className='col-md-2 col-md-offset-4'>
                <Link to='/register'>
                  <button type='button'>Empieza ahora</button>
                </Link>
              </div>
              <div className='col-md-2'>
                <button type='button' onClick={knowMore}>
                  ¿Cómo funciona?
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container-fluid'>
        <div id='tutorial' className='navbar-container row'>
          <div className='navbar-button-container col-md-4'></div>
          <div className='navbar-title-container col-md-4'>
            <img src={logo} alt='logo' />
          </div>
          <div className='navbar-button-container col-md-4'>
            <Link to='/register'>
              <button type='button' className='button-right'>Empieza ahora</button>
            </Link>
          </div>
        </div>
      </div>

      <div className='container-fluid'>
        <div className='home-title-container row'>
          <div className='col-md-4 col-md-offset-4'>
            <h2>¿Cómo funciona?</h2>
          </div>
        </div>
        <div className='steps-container row'>
          <div className='col-md-8 col-md-offset-2'>
            <div key='step-1' className='col-md-3'>
              <div className='step'>
                <img src={step1} alt='step 1' />
                <p>Elige que quieres en tu paquete.</p>
              </div>
            </div>
            <div key='step-2' className='col-md-3'>
              <div className='step'>
                <img src={step2} alt='step 2' />
                <p>Selecciona los detalles.</p>
              </div>
            </div>
            <div key='step-3' className='col-md-3'>
              <div className='step'>
                <img src={step3} alt='step 3' />
                <p>Selecciona las fechas de entrega.</p>
              </div>
            </div>
            <div key='step-4' className='col-md-3'>
              <div className='step'>
                <img src={step4} alt='step 4' />
                <p>Disfruta con tu mascota.</p>
              </div>
            </div>
          </div>
        </div>
        <div className='charity-container row'>
          <div className='col-md-8 col-md-offset-2'>
            <img src={dogsbanner} alt='banner' />
          </div>
        </div>
        <div className='footer-container row'>
          <div className='col-md-8 col-md-offset-2'>
            <h4>
              <i className='em em-dog' />
              Recuerda que por cada compra donamos comida
              <i className='em em-dog' />
              <br />
              y apoyamos a una fundación de animalitos abandonados
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;