const orderAges = [
  {
    id: 'cachorro',
    title: 'Cachorro',
    subtitle: null,
    img: '/assets/images/puppydog.png'
  },
  {
    id: 'adulto',
    title: 'Adulto',
    subtitle: null,
    img: '/assets/images/adultdog.png'
  }
];

export default orderAges;