const orderItems = [
  {
    id: 'food',
    title: 'Comida',
    subtitle: 'El precio varía',
    img: '/assets/images/dogfood.png'
  },
  {
    id: 'snacks',
    title: 'Galletitas',
    subtitle: '$30,000 COP',
    img: '/assets/images/dogtreats.png'
  },
  {
    id: 'toys',
    title: 'Juguete',
    subtitle: '$10,000 COP',
    img: '/assets/images/dogtoys.png'
  }
];

export default orderItems;
