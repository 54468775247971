import { Link } from 'react-router-dom';

import '../css/notfound.css';

import logo from '../images/logo.png';

function NotFound() {
  return (
    <div id='not-found'>
      <div className='container-fluid'>
        <div className='logo-container row'>
          <div className='col-md-4 col-md-offset-4'>
            <img src={logo} alt='logo' />
          </div>
        </div>
        <div className='row'>
          <div>
            <h2>Página no encontrada</h2>
            <Link to='/'>Volver</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
