const orderSizes = [
  {
    id: 'pequeña',
    title: 'Pequeño',
    subtitle: null,
    img: '/assets/images/smalldog.png'
  },
  {
    id: 'mediana',
    title: 'Mediano',
    subtitle: null,
    img: '/assets/images/middog.png'
  },
  {
    id: 'grande',
    title: 'Grandote',
    subtitle: null,
    img: '/assets/images/bigdog.png'
  }
];

export default orderSizes;
