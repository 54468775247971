import orderFoods from '../data/orderFoods.js';
import Items from './Items.js';

function Step4(props) {
  const filteredOrderFoods = orderFoods.filter((food) =>
    food.age.includes(props.orderAge.id) &&
      food.size.includes(props.orderSize.id) &&
        food.brand === props.orderBrand.id);

  return (
    <div className='container-fluid'>
      <Items
        itemExtraClassName='item-text-outside'
        items={filteredOrderFoods}
        textOutside={true}
        columnWidth={3}
        isSelected={props.isOrderFood}
        onClick={props.setOrderFood} />
    </div>
  );
}

export default Step4;
