function Item(props) {
  let itemClassName = 'item';
  if (props.isSelected(props.item.id)) {
    itemClassName += ' selected';
  }
  if (props.itemExtraClassName) {
    itemClassName += ' ' + props.itemExtraClassName;
  }

  const columnWidth = `col-md-${props.columnWidth}`;
  const columnOffset = props.columnOffset ? `col-md-offset-${props.columnOffset}` : null;
  const divClassName = columnWidth + (columnOffset && props.index === 0 ? ' ' + columnOffset : '');

  return (
    <div key={props.item.id} className={divClassName}>
      <div className={itemClassName} onClick={() => props.onClick(props.item)}>
        <div className='item-image-container'>
          <img src={`${process.env.PUBLIC_URL}${props.item.img}`} alt='' />
        </div>
        {
          props.item.title &&
          <div className='item-title-container'>
            <h4>
              {props.item.title}
              {
                props.item.subtitle &&
                <span className='item-subtitle-container'>
                  {props.item.subtitle}
                </span>
              }
            </h4>
          </div>
        }
      </div>
    </div>
  );
}

export default Item;